import { useEffect, useState } from "react";
import "./App.css";
import "./CommunityProgressPage.css";
import { getDataFromSpreadsheet } from "./fetchData";
import { Major, MajorTypes, UpgradeEvent } from "./types";
import BadgeAmbition from "./assets/BadgeAmbition.svg";
import BadgeConnection from "./assets/BadgeConnection.svg";
import BadgePerception from "./assets/BadgePerception.svg";
import BadgeEpression from "./assets/BadgeExpression.svg";
import firstPlace from "./assets/FirstPlace.svg";
import secondPlace from "./assets/SecondPlace.svg";
import thirdPlace from "./assets/ThirdPlace.svg";
import RecentActivity from "./RecentActivity";
import cx from "classnames";
import Loading from "./Loading";
import Uni1 from "./assets/Uni1.jpg";
import Uni2 from "./assets/Uni2.jpg";
import { MOBILE_BREAKPOINT } from "./constants";
import {
  HowToVote,
  Leaderboard,
  MilitaryTech,
  People,
  Star,
  StarOutline,
} from "@mui/icons-material";
import { getDateDisplay, getEventDescription } from "./utils";
import { LinearProgress } from "@mui/material";

const getUpgradeEvents = (data: string[][]): { [key: string]: UpgradeEvent[] } => {
  if (!data) return {};

  let events: UpgradeEvent[] = [];
  data.forEach((row, index) => {
    // Skip header row
    if (index !== 0) {
      events.push({
        datetime: new Date(Date.parse(row[0])),
        major: row[1],
        level: Number(row[2]),
        upgrade: row[3],
        powerType: row[4],
        amount: Number(row[5]),
        decidedBy: row[6],
      });
    }
  });
  events.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
  const eventsByMajor: { [key: string]: UpgradeEvent[] } = {};
  events.forEach((event) => {
    if (eventsByMajor[event.major]) {
      eventsByMajor[event.major].push(event);
    } else {
      eventsByMajor[event.major] = [event];
    }
  });
  return eventsByMajor;
};

function CommunityProgressPage() {
  const [highestXp, setHighestXp] = useState(100);
  const [lowestXp, setLowestXp] = useState(3000);
  const [isMobile, setIsMobile] = useState(false);
  const [majors, setMajors] = useState<Major[]>([]);
  const [selectedMajor, setSelectedMajor] = useState(0);
  const [upgradeEventsByMajor, setUpgradeEventsByMajor] = useState<{
    [key: string]: UpgradeEvent[];
  }>({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT); // Define your desired breakpoint
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Fetch data every 5 minutes
    const interval = setInterval(() => {
      fetchData();
    }, 300000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const fetchData = () => {
    const overviewSheet = "Overview";
    getDataFromSpreadsheet(overviewSheet)
      .then((data) => {
        console.log("Majors:", data);
        // Process the retrieved data here
        const majors = getMajors(data);
        console.log(majors);
        setMajors(majors);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const upgradeEvents = "Major Upgrades";
    getDataFromSpreadsheet(upgradeEvents)
      .then((data) => {
        console.log("Upgrade Events:", data);
        // Process the retrieved data here
        const events = getUpgradeEvents(data);
        console.log(events);
        setUpgradeEventsByMajor(events);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getMajors = (data: string[][]): Major[] => {
    if (!data) return [];

    let majors: Major[] = [];
    data.forEach((row, index) => {
      const majorName = row[0].trim();
      // Skip header row
      if (index !== 0) {
        majors.push({
          name: majorName,
          numMembers: Number(row[1]),
          totalXP: Number(row[2]),
          level: Number(row[3]),
          xpToNextLevel: Number(row[4]),
          rank: Number(row[5]),
          maxMembers: Number(row[6]),
          numFirstPlaceWins: Number(row[7]),
          votingPower: Number(row[8]),
        });
        const totalXp = Number(row[2]);
        if (totalXp > highestXp) {
          setHighestXp(totalXp);
        }
        if (totalXp < lowestXp) {
          setLowestXp(totalXp);
        }
      }
    });
    majors.sort((a, b) => b.totalXP - a.totalXP);
    return majors;
  };

  const selectMajor = (index: number) => {
    setSelectedMajor(index);
  };

  const majorCard = (major: Major, majorIndex: number) => {
    if (!major) return <></>;

    let imgSrc = "";
    if (major.name === MajorTypes.EXPRESSION) {
      imgSrc = BadgeEpression;
    } else if (major.name === MajorTypes.PERCEPTION) {
      imgSrc = BadgePerception;
    } else if (major.name === MajorTypes.CONNECTION) {
      imgSrc = BadgeConnection;
    } else if (major.name === MajorTypes.AMBITION) {
      imgSrc = BadgeAmbition;
    }

    let ribbon = null;
    if (major.rank === 1) {
      ribbon = firstPlace;
    } else if (major.rank === 2) {
      ribbon = secondPlace;
    } else if (major.rank === 3) {
      ribbon = thirdPlace;
    }

    return (
      <>
        <div
          className={cx("major-tab", {
            "major-tab-selected": selectedMajor === majorIndex,
            "major-tab-first": majorIndex === 0,
            "major-tab-mobile": isMobile,
          })}
          onClick={() => selectMajor(majorIndex)}
        >
          {ribbon && !isMobile && (
            <img
              className={cx("cp-ribbon", { "cp-ribbon-first": majorIndex === 0 })}
              src={ribbon}
              alt={`${major.rank} rank ribbon`}
            />
          )}
          <img className="major-tab-badge" src={imgSrc} alt={`${major.name} badge`} />
          {!isMobile && (
            <div
              className={cx("major-tab-info", {
                "major-tab-info-selected": selectedMajor === majorIndex,
              })}
            >
              <span className="major-tab-name">{major.name}</span>
              <span className="major-tab-xp">{major.totalXP} XP</span>
              <span className="major-tab-xp">Level {major.level}</span>
            </div>
          )}
        </div>
      </>
    );
  };

  const majorProgress = (major: Major, majorIndex: number) => {
    const normalizedValue = normalise(major.totalXP);

    return (
      <>
        <span>{major.name}</span>
        <LinearProgress
          className={`progress-line progress-line-${majorIndex}`}
          color="inherit"
          variant="determinate"
          value={normalizedValue}
        />
      </>
    );
  };

  let ribbon = null;
  if (majors[selectedMajor]) {
    if (majors[selectedMajor].rank === 1) {
      ribbon = firstPlace;
    } else if (majors[selectedMajor].rank === 2) {
      ribbon = secondPlace;
    } else if (majors[selectedMajor].rank === 3) {
      ribbon = thirdPlace;
    }
  }

  const displayRecentEvents = (event: UpgradeEvent, eventIndex: number) => {
    if (eventIndex < 3) {
      const dateDisplay = getDateDisplay(event);

      return (
        <div className="recent-events">
          {dateDisplay} - {getEventDescription(event)}
        </div>
      );
    }
  };

  const normalise = (value: number) => {
    const MAX = Math.ceil(highestXp / 1000) * 1000;
    const MIN = Math.floor(lowestXp / 1000) * 1000;
    const normalized = ((value - MIN) * 100) / (MAX - MIN);

    return normalized;
  };

  return (
    <>
      {/* {!!majors && (
        <div className="progress-div">
          {!!majors ? (
            majors.map((major, majorIndex) => {
              return majorProgress(major, majorIndex);
            })
          ) : (
            <></>
          )}
        </div>
      )} */}
      <div className="intro-block">
        <div className={cx("major-tab-row", { "major-tab-row-mobile": isMobile })}>
          {!!majors ? (
            majors.map((major, majorIndex) => {
              return majorCard(major, majorIndex);
            })
          ) : (
            <Loading small />
          )}
        </div>
        <div className="major-tab-row">
          {!!majors && majors[selectedMajor] ? (
            <>
              <div className={cx("uni-div", { "uni-div-mobile": isMobile })}>
                {isMobile && (
                  <div className="uni-div-info-box-mobile">
                    <div className="uni-div-info-mobile">
                      <span className="major-tab-name">{majors[selectedMajor].name}</span>
                      <span className="major-tab-xp">{majors[selectedMajor].totalXP} XP</span>
                      <span className="major-tab-xp">Level {majors[selectedMajor].level}</span>
                    </div>
                    <div>
                      {ribbon && (
                        <img src={ribbon} alt={`${majors[selectedMajor].rank} rank ribbon`} />
                      )}
                    </div>
                  </div>
                )}
                {majors[selectedMajor].level < 4 && (
                  <img
                    className={cx("uni-img", { "uni-img-mobile": isMobile })}
                    src={Uni1}
                    alt="University Black & White"
                  />
                )}
                {majors[selectedMajor].level >= 4 && (
                  <img
                    className={cx("uni-img", { "uni-img-mobile": isMobile })}
                    src={Uni2}
                    alt="University Color"
                  />
                )}
                <div className={cx("stats-box", { "stats-box-mobile": isMobile })}>
                  <span>
                    <Leaderboard className="stat-icon" />
                    Level {majors[selectedMajor].level}
                  </span>
                  <span>
                    <People className="stat-icon" />
                    Members: {majors[selectedMajor].numMembers} / {majors[selectedMajor].maxMembers}
                  </span>
                  <span>
                    <Star className="stat-icon" />
                    Total XP: {majors[selectedMajor].totalXP}
                  </span>
                  <span>
                    <StarOutline className="stat-icon" />
                    XP to next level: {majors[selectedMajor].xpToNextLevel}
                  </span>
                  <span>
                    <MilitaryTech className="stat-icon" />
                    Number of 1st place wins: {majors[selectedMajor].numFirstPlaceWins}
                  </span>
                  <span>
                    <HowToVote className="stat-icon" />
                    Voting power: {majors[selectedMajor].votingPower}%
                  </span>
                  <p></p>
                  <span>Recent upgrades:</span>
                  {upgradeEventsByMajor &&
                    upgradeEventsByMajor[majors[selectedMajor].name] &&
                    upgradeEventsByMajor[majors[selectedMajor].name].map((event, eventIndex) => {
                      return displayRecentEvents(event, eventIndex);
                    })}
                </div>
              </div>
            </>
          ) : (
            <Loading small />
          )}
        </div>
      </div>
      <RecentActivity />
    </>
  );
}

export default CommunityProgressPage;
