import { Box, Button, Card, CardActions, CardContent, Modal } from "@mui/material";
import "./MajorCard.css";
import { useState } from "react";

interface Props {
  name: string;
  description: string;
  badgeImgPath: string;
  charImgPath: string;
  guideName: string;
  guideDescription: string;
}

const MajorCard = (props: Props) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const handleOpenDetails = () => setIsDetailsOpen(true);
  const handleCloseDetails = () => setIsDetailsOpen(false);

  return (
    <>
      <Card className="major-card" sx={{ minWidth: 275 }}>
        <CardContent className="card-content">
          <div className="card-first-half">
            <img className="major-badge-img" src={props.badgeImgPath} alt={props.name + " badge"} />
            <span className="card-name">{props.name}</span>
            <span className="card-description">{props.description}</span>
          </div>
          <div className="card-second-half">
            <img className="char-img" src={props.charImgPath} alt={props.name + " character"} />
          </div>
        </CardContent>
        <CardActions>
          <Button className="card-button" size="small" onClick={handleOpenDetails}>
            Read More
          </Button>
        </CardActions>
      </Card>
      <Modal open={isDetailsOpen} onClose={handleCloseDetails}>
        <Box className="modal">
          <div className="card-content">
            <div className="text-content">
              <span className="modal-major">Major</span>
              <span className="modal-name">{props.name}</span>
              <span className="modal-subheader">Description</span>
              <span className="modal-subtext">{props.description}</span>
              <span className="modal-subheader">Student Guide: {props.guideName}</span>
              <span className="modal-subtext">{props.guideDescription}</span>
              <span className="modal-subheader">Emblem</span>
              <img src={props.badgeImgPath} alt={props.name + " badge"} />
            </div>
            <div>
              <img
                className="modal-char-img"
                src={props.charImgPath}
                alt={props.name + " character"}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MajorCard;
