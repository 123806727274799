import { Divider } from "@mui/material";
import "./App.css";
import "./Footer.css";
import AnimoreLogo from "./assets/AnimoreLogo.svg";
import discordIcon from "./assets/DiscordIcon.svg";
import twitterIcon from "./assets/TwitterIcon.svg";
import { DISCORD_LINK, TWITTER_LINK } from "./constants";

function Footer() {
  const navigateToDiscord = () => {
    window.open(DISCORD_LINK, "_blank");
  };

  const navigateToTwitter = () => {
    window.open(TWITTER_LINK, "_blank");
  };

  return (
    <div className="footer-div">
      <div className="footer-row">
        <img className="animore-logo" src={AnimoreLogo} alt="Animore logo" />
        <img
          className="social-btn"
          src={discordIcon}
          alt="Discord icon"
          onClick={navigateToDiscord}
        />
        <img
          className="social-btn"
          src={twitterIcon}
          alt="Twitter icon"
          onClick={navigateToTwitter}
        />
      </div>
      <div className="footer-row credit-div">Built by Krystal Kallarackal</div>
      <Divider />
      <span className="copyright">Copyright © 2022 Animore. All Rights Reserved.</span>
    </div>
  );
}

export default Footer;
