import axios from "axios";

export async function getDataFromSpreadsheet(sheetName: string) {
  const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
  const SHEETS_API_KEY = process.env.REACT_APP_SHEETS_API_KEY;

  const apiKey = SHEETS_API_KEY ? SHEETS_API_KEY : "";
  const spreadsheetId = SPREADSHEET_ID ? SPREADSHEET_ID : "";
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const data = response.data.values;
    return data;
  } catch (error) {
    console.error("Error fetching data from Google Sheets:", error);
    return null;
  }
}
