import { useEffect, useState } from "react";
import "./App.css";
import "./RecentActivity.css";
import { getDataFromSpreadsheet } from "./fetchData";
import { Event, EventTypes, GameEvent, UpgradeEvent } from "./types";
import Loading from "./Loading";
import { MOBILE_BREAKPOINT } from "./constants";
import cx from "classnames";
import { getDateDisplay, getEventDescription } from "./utils";

const getEvents = (data: string[][]): Event[] => {
  if (!data) return [];

  let events: Event[] = [];
  data.forEach((row, index) => {
    // Skip header row
    if (index !== 0) {
      events.push({
        datetime: new Date(Date.parse(row[0])),
        eventType: EventTypes.GAME,
        event: {
          userName: row[1],
          xp: Number(row[2]),
          cp: Number(row[3]),
          placement: Number(row[4]),
          gameEvent: row[5],
          major: row[6],
          datetime: new Date(Date.parse(row[0])),
        },
      });
    }
  });
  events.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
  return events;
};

const getMajorUpgradeEvents = (data: string[][]): Event[] => {
  if (!data) return [];

  let events: Event[] = [];
  data.forEach((row, index) => {
    // Skip header row
    if (index !== 0) {
      events.push({
        datetime: new Date(Date.parse(row[0])),
        eventType: EventTypes.UPGRADE,
        event: {
          datetime: new Date(Date.parse(row[0])),
          major: row[1],
          level: Number(row[2]),
          upgrade: row[3],
          powerType: row[4],
          amount: Number(row[5]),
          decidedBy: row[6],
        },
      });
    }
  });
  events.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
  return events;
};

function RecentActivity() {
  const [isMobile, setIsMobile] = useState(false);
  const [gameEvents, setGameEvents] = useState<Event[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [majorUpgradeEvents, setMajorUpgradeEvents] = useState<Event[]>([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT); // Define your desired breakpoint
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Fetch data every 5 minutes
    const interval = setInterval(() => {
      fetchData();
    }, 300000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let allEvents = [...gameEvents, ...majorUpgradeEvents];
    allEvents.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());

    setEvents(allEvents);
  }, [gameEvents, majorUpgradeEvents]);

  const fetchData = () => {
    const offChainEventsSheet = "Off-chain events";
    getDataFromSpreadsheet(offChainEventsSheet)
      .then((data) => {
        console.log("Events:", data);
        // Process the retrieved data here
        const events = getEvents(data);
        console.log(events);
        setGameEvents(events);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const upgradeEventsSheet = "Major Upgrades";
    getDataFromSpreadsheet(upgradeEventsSheet)
      .then((data) => {
        console.log("Major Upgrade Events:", data);
        // Process the retrieved data here
        const events = getMajorUpgradeEvents(data);
        console.log(events);
        setMajorUpgradeEvents(events);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const displayGameEvent = (event: GameEvent, dateDisplay: string) => {
    return (
      <div className={cx("event-card", { "event-card-mobile": isMobile })}>
        {!isMobile && <span className="event-title">EVENT</span>}
        <span className="event-text">
          <span className="event-user">{event.userName.split("#")[0]}</span> won {event.xp} XP in{" "}
          {event.gameEvent} for {event.major}
        </span>
        <span className="event-date">{dateDisplay}</span>
      </div>
    );
  };

  const displayUpgradeEvent = (event: UpgradeEvent, dateDisplay: string) => {
    let eventString = getEventDescription(event);

    if (eventString !== "") {
      return (
        <div className={cx("event-card", { "event-card-mobile": isMobile })}>
          {!isMobile && <span className="event-title">UPGRADE</span>}
          <span className="event-text">{eventString}</span>
          <span className="event-date">{dateDisplay}</span>
        </div>
      );
    }
  };

  const eventItem = (event: Event) => {
    const dateDisplay = getDateDisplay(event);

    if (event.eventType === EventTypes.GAME) {
      return displayGameEvent(event.event as GameEvent, dateDisplay);
    } else {
      return displayUpgradeEvent(event.event as UpgradeEvent, dateDisplay);
    }
  };

  return (
    <div className={cx("recent-activity-div", { "recent-activity-div-mobile": isMobile })}>
      {!!events && <span className="recent-activity-header">Recent Activity</span>}
      {!!events ? (
        events.map((event) => {
          if (!event) return <></>;
          return <>{eventItem(event)}</>;
        })
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default RecentActivity;
