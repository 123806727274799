import "./App.css";
import "./Loading.css";
import cx from "classnames";

import { CircularProgress } from "@mui/material";

function Loading(props: { small?: boolean }) {
  return (
    <div className={cx("loading-buffer", { small: props.small })}>
      <CircularProgress />
    </div>
  );
}

export default Loading;
