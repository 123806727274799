import { Box, IconButton, Menu, MenuItem, Tab, Tabs } from "@mui/material";
import "./App.css";
import React, { useEffect, useState } from "react";
import MajorsPage from "./MajorsPage";
import LeaderboardPage from "./LeaderboardPage";
import CommunityProgressPage from "./CommunityProgressPage";
import AnimoreLogo from "./assets/AnimoreLogo.svg";
import Footer from "./Footer";
import GamesPage from "./GamesPage";
import ShopPage from "./ShopPage";
import MenuIcon from "@mui/icons-material/Menu";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Navigation } from "./types";
import { MOBILE_BREAKPOINT } from "./constants";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedTab, setSelectedTab] = useState(Navigation.MAJORS);
  const [mobileAnchorEl, setMobileAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileAnchorEl);

  const navigate = useNavigate();

  const handleOpenMobileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMobileAnchorEl(event.currentTarget);
  };
  const handleCloseMobileMenu = () => {
    setMobileAnchorEl(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT); // Define your desired breakpoint
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChangeTab = (newValue: Navigation) => {
    setSelectedTab(newValue);
    navigate(newValue);
    handleCloseMobileMenu();
  };

  return (
    <div
      className="App"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", alignItems: "center" }}>
        {isMobile && (
          <>
            <IconButton
              aria-controls={isMobileMenuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isMobileMenuOpen ? "true" : undefined}
              onClick={handleOpenMobileMenu}
            >
              <MenuIcon className="menu-icon" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={mobileAnchorEl}
              open={isMobileMenuOpen}
              onClose={handleCloseMobileMenu}
            >
              <MenuItem onClick={() => handleChangeTab(Navigation.MAJORS)}>MAJORS</MenuItem>
              <MenuItem onClick={() => handleChangeTab(Navigation.LEADERBOARD)}>
                LEADERBOARD
              </MenuItem>
              <MenuItem onClick={() => handleChangeTab(Navigation.COMMUNITY)}>
                COMMUNITY PROGRESS
              </MenuItem>
              <MenuItem onClick={() => handleChangeTab(Navigation.GAMES)}>GAMES</MenuItem>
            </Menu>
          </>
        )}
        <img
          className="animore-logo"
          src={AnimoreLogo}
          alt="Animore logo"
          onClick={() => handleChangeTab(Navigation.MAJORS)}
        />
        {!isMobile && (
          <Tabs
            value={selectedTab}
            onChange={(e, value) => handleChangeTab(value)}
            TabIndicatorProps={{ style: { backgroundColor: "#f8cf49" } }}
          >
            <Tab label="MAJORS" value={Navigation.MAJORS} />
            <Tab label="LEADERBOARD" value={Navigation.LEADERBOARD} />
            <Tab label="COMMUNITY PROGRESS" value={Navigation.COMMUNITY} />
            <Tab label="GAMES" value={Navigation.GAMES} />
            {/* <Tab label="SHOP" value={Navigation.SHOP} /> */}
          </Tabs>
        )}
      </Box>
      <Routes>
        <Route path={Navigation.MAJORS} Component={MajorsPage} />
        <Route path={Navigation.LEADERBOARD} Component={LeaderboardPage} />
        <Route path={Navigation.COMMUNITY} Component={CommunityProgressPage} />
        <Route path={Navigation.GAMES} Component={GamesPage} />
        <Route path={Navigation.SHOP} Component={ShopPage} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
