import "./App.css";
import "./MajorPage.css";
import MajorCard from "./MajorCard";
import BadgeAmbition from "./assets/BadgeAmbition.svg";
import BadgeConnection from "./assets/BadgeConnection.svg";
import BadgePerception from "./assets/BadgePerception.svg";
import BadgeEpression from "./assets/BadgeExpression.svg";
import CharAmbition from "./assets/vincent.png";
import CharConnection from "./assets/hasana.png";
import CharPerception from "./assets/nova.png";
import CharExpression from "./assets/alexiel.png";
import { MajorTypes } from "./types";
import { DISCORD_LINK } from "./constants";
import { Button } from "@mui/material";

const Majors = [
  {
    name: MajorTypes.AMBITION,
    description:
      "To expand my personal power by learning skills of bringing ideas into physical form.",
    badgeImgPath: BadgeAmbition,
    charImgPath: CharAmbition,
    guideName: "Vincent",
    guideDescription:
      "Vincent, a determined orphan-turned-elite bodyguard, leverages his street-smart resilience to defy societal expectations.",
  },
  {
    name: MajorTypes.CONNECTION,
    description:
      "To build connections with likeminded people through opening constructive forms of dialogue.",
    badgeImgPath: BadgeConnection,
    charImgPath: CharConnection,
    guideName: "Hasana",
    guideDescription: "Hasana, a priestess utilizing her divination abilities, mediation tactics and wisdom to navigate complex societal issues.",
  },
  {
    name: MajorTypes.PERCEPTION,
    description: "To develop awareness of what I see and what it means.",
    badgeImgPath: BadgePerception,
    charImgPath: CharPerception,
    guideName: "Nova",
    guideDescription: "Nova, a trailblazing Karanin warrior, overcomes societal norms and personal struggles to redefine gender expectations in combat.",
  },
  {
    name: MajorTypes.EXPRESSION,
    description:
      "To bring forward my unique self expression and find out how I can blend my efforts with other creators.",
    badgeImgPath: BadgeEpression,
    charImgPath: CharExpression,
    guideName: "Alexiel",
    guideDescription: "Alexiel, the Prince of Liwaciel, driven by his desire to protect, finds his destiny intertwined with the Celestial Fractal Liwa.",
  },
];

function MajorsPage() {
  return (
    <>
      <div className="intro-block">
        <span className="lucerna-uni">Lucerna University Majors</span>
        <span className="winning-header">What Does Winning Mean to You?</span>
        <span className="find-out-sub">Join the major that best suits your personality.</span>
      </div>
      <div className="major-card-div">
        {Majors.map((major) => {
          return (
            <MajorCard
              key={major.name}
              name={major.name}
              description={major.description}
              badgeImgPath={major.badgeImgPath}
              charImgPath={major.charImgPath}
              guideName={major.guideName}
              guideDescription={major.guideDescription}
            />
          );
        })}
      </div>
      <div className="how-to-join-div">
        <span className="join-header">Ready to Play?</span>
        <span className="join-text">
          {"Short version: Join Discord → Choose a Major → Play Games → Level up your team"}
        </span>
        <span className="join-text">
          The Test Trials of Animoré are fun games where you can join one of four teams called
          Majors. Each Major has different characteristics, so you can choose the one that most
          resonates with you. Players get nominated to represent their Majors in events and games.
          Participating in these events will give you the chance to earn points for your team, allow
          your team to grow and get cool rewards! Each team has an individual University Space which
          can be upgraded and enhanced as the Major levels up.
        </span>
        <Button className="join-btn" href={DISCORD_LINK} target="_blank" rel="noreferrer">
          Join the Discord
        </Button>
        <span className="join-text">Choose a major.</span>
        <span className="join-text">
          When you have made the choice of which major you would like to join, please tag @Jandro in
          the #free-agents space and state: "I would love to be a part of [MAJOR]" The department
          head from each major can then decide whether you will be accepted or if you must apply to
          another major. Invite your friends to play for great fun, awesome events, and a kind
          community!
        </span>
      </div>
    </>
  );
}

export default MajorsPage;
