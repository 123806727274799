import { Card, CardContent } from "@mui/material";
import "./App.css";
import "./GamesPage.css";
import SpellCast from "./assets/SpellCast.png";
import PuttPutt from "./assets/PuttPutt.png";
import Codenames from "./assets/Codenames.png";
import Landio from "./assets/Landio.png";
import { useEffect, useState } from "react";
import cx from "classnames";
import { MOBILE_BREAKPOINT } from "./constants";

interface GameType {
  name: string;
  img?: string;
  description: string;
}

const Games: GameType[] = [
  {
    name: "SpellCast",
    img: SpellCast,
    description:
      "SpellCast in Discord is a word game where players compete to create words out of adjacent letters. The highest score after 5 rounds wins.",
  },
  {
    name: "Putt Putt",
    img: PuttPutt,
    description: "Mini golf, mega drama. Now with more levels and new power-ups.",
  },
  {
    name: "Codenames",
    img: Codenames,
    description:
      "Codenames is a team-based board game where players give one-word clues to help their teammates guess specific words on a grid. The objective is to correctly identify all your team's secret agents while avoiding the words associated with the opposing team or the assassin. It requires strategic communication and deduction skills to win.",
  },
  {
    name: "Land-io",
    img: Landio,
    description: "Claim territory and become outstanding in your field(s).",
  },
  {
    name: "Blazing 8s",
    description: "Get rid of your hand first or cry trying.",
  },
  {
    name: "Letter League",
    description: "Wow, that’s a real word? That’s a lot of points.",
  },
  {
    name: "Gartic Phone",
    description: "Bring the phrase “sneaky stinky shoebill” to life by drawing it… poorly.",
  },
  {
    name: "Poker Night 2.0",
    description: "Give your Voice channel that Vegas high-roller vibe.",
  },
  {
    name: "Know What I Meme",
    description: "The perfect place to repost the same capybara GIF for the hundredth time.",
  },
  {
    name: "Sketch Heads",
    description: "Okay, so they’re either drawing a snake or a sandwich.",
  },
  {
    name: "Chess in the Park",
    description: "One of the world’s classiest board games.",
  },
  {
    name: "Checkers in the Park",
    description: "More laid-back than chess, and even more kings.",
  },
];

function GamesPage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT); // Define your desired breakpoint
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const gameCard = (game: GameType) => {
    return (
      <Card className={cx("game-card", { "game-card-mobile": isMobile })} sx={{ minWidth: 275 }}>
        <CardContent className="game-card-content">
          <span className="game-card-name">{game.name}</span>
          {game.img && <img className="game-img" src={game.img} alt={game.name} />}
          <span className="game-card-description">{game.description}</span>
        </CardContent>
        {/* <CardActions>
          <Button className="game-card-button">Read More</Button>
        </CardActions> */}
      </Card>
    );
  };

  return (
    <div className="game-div">
      {Games.map((game) => {
        return gameCard(game);
      })}
    </div>
  );
}

export default GamesPage;
