import { useEffect, useState } from "react";
import "./App.css";
import "./LeaderboardPage.css";
import { User } from "./types";
import Tier1 from "./assets/Tier1.svg";
import firstPlace from "./assets/FirstPlace.svg";
import secondPlace from "./assets/SecondPlace.svg";
import thirdPlace from "./assets/ThirdPlace.svg";
import cx from "classnames";
import leaderboardHeader from "./assets/LeaderboardHeader.svg";
import { getDataFromSpreadsheet } from "./fetchData";
import { Tooltip } from "@mui/material";
import Loading from "./Loading";
import { MOBILE_BREAKPOINT } from "./constants";

const getUsers = (data: string[][]): User[] => {
  if (!data) return [];

  const users: User[] = [];
  data.forEach((row, index) => {
    // Skip header row
    if (index !== 0) {
      users.push({
        name: row[0],
        discordId: row[1],
        major: row[2],
        totalCP: row[5],
        rank: Number(row[6]),
      });
    }
  });
  users.sort((a, b) => a.rank - b.rank);
  return users;
};

function LeaderboardPage() {
  const [isMobile, setIsMobile] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT); // Define your desired breakpoint
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Fetch data every 5 minutes
    const interval = setInterval(() => {
      fetchData();
    }, 300000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const fetchData = () => {
    const sheetName = "Account Info";
    getDataFromSpreadsheet(sheetName)
      .then((data) => {
        console.log("Users:", data);
        // Process the retrieved data here
        const users = getUsers(data);
        console.log(users);
        setUsers(users);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPlayerImg = (user: User, isLeaderImg = false) => {
    return (
      <img
        className={cx("player-img", { "leader-img": isLeaderImg })}
        src={`./users/${user.discordId}.png`}
        alt="Player"
      />
    );
  };

  const playerCard = (user: User, index: number) => {
    return (
      <div className={cx("player-card", { "player-card-mobile": isMobile })}>
        <span className="player-position">{user.rank}</span>
        {getPlayerImg(user)}
        <span className="player-name">
          {user.name.split("#")[0]} - {user.major}
        </span>
        <Tooltip title="Combat points earned">
          <div className="points-div">
            <img className="tier-img" src={Tier1} alt="Tier" />
            <span className="points">{user.totalCP}</span>
          </div>
        </Tooltip>
      </div>
    );
  };

  const secondThirdPlayerCards = (user: User, position: number) => {
    return (
      <div
        className={cx("leader23-card", {
          "leader2-card": position === 2,
          "leader3-card": position === 3,
        })}
      >
        {getPlayerImg(user, true)}
        <span className="player-name">
          {user.name.split("#")[0]} - {user.major}
        </span>
        <Tooltip title="Combat points earned">
          <div className="points-div">
            <img className="tier-img" src={Tier1} alt="Tier" />
            <span className="points">{user.totalCP}</span>
          </div>
        </Tooltip>
        {user.rank === 2 ? (
          <img className="leader-ribbon" src={secondPlace} alt="Second Place Ribbon" />
        ) : (
          <img className="leader-ribbon" src={thirdPlace} alt="Third Place Ribbon" />
        )}
      </div>
    );
  };

  const firstPlacePlayerCard = (user: User) => {
    return (
      <div className="leader1-card">
        {getPlayerImg(user, true)}
        <span className="player-name">
          {user.name.split("#")[0]} - {user.major}
        </span>
        <Tooltip title="Combat points earned">
          <div className="points-div">
            <img className="tier-img" src={Tier1} alt="Tier" />
            <span className="points">{user.totalCP}</span>
          </div>
        </Tooltip>
        <img className="leader-ribbon" src={firstPlace} alt="First Place Ribbon" />
      </div>
    );
  };

  return (
    <>
      {!!users && !!users.length ? (
        <>
          <img
            className={cx("leaderboard-header", { "leaderboard-header-mobile": isMobile })}
            src={leaderboardHeader}
            alt="Player"
          />
          <div className={cx({ "leaderboard-container": !isMobile })}>
            {!isMobile && (
              <div className="leaders-div">
                <div>{secondThirdPlayerCards(users[1], 2)}</div>
                <div>{firstPlacePlayerCard(users[0])}</div>
                <div>{secondThirdPlayerCards(users[2], 3)}</div>
              </div>
            )}
            <div className="players-div">
              {users.map((user, userIndex) => {
                if ((userIndex > 2 && !isMobile) || isMobile) {
                  return playerCard(user, userIndex);
                }
                return <></>;
              })}
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default LeaderboardPage;
