import { Event, GameEvent, UpgradeEvent } from "./types";

export function getEventDescription(event: UpgradeEvent) {
  const isAutoUpgrade = event.decidedBy === "AUTO" ? true : false;

  let eventString = "";
  if (isAutoUpgrade) {
    if (event.upgrade === "Event Voting") {
      eventString = `At Level ${event.level}, ${event.major} automatically receives a ${event.amount}% increase in event voting power`;
    } else if (event.upgrade === "Max Members") {
      eventString = `At Level ${event.level}, ${event.major} automatically receives an increase of ${event.amount} maxium members`;
    } else if (event.upgrade === "Cross-Major Selection") {
      eventString = `At Level ${event.level}, ${event.major} automatically receives a ${event.amount}% increase in cross-major selection`;
    } else if (event.upgrade === "University Space") {
      eventString = `At Level ${event.level}, ${event.major} automatically upgrades their University space`;
    } else if (event.upgrade === "Event VETO (One-Time)") {
      eventString = `At Level ${event.level}, ${event.major} automatically receives a one time event veto`;
    }
  } else {
    if (event.upgrade === "Event Voting") {
      eventString = `At Level ${event.level}, ${event.decidedBy.split("#")[0]} decided that ${
        event.major
      } will receive a ${event.amount}% increase in event voting power`;
    } else if (event.upgrade === "Max Members") {
      eventString = `At Level ${event.level}, ${event.decidedBy.split("#")[0]} decided that ${
        event.major
      } will receive an increase of ${event.amount} maxium members`;
    } else if (event.upgrade === "Cross-Major Selection") {
      eventString = `At Level ${event.level}, ${event.decidedBy.split("#")[0]} decided that ${
        event.major
      } will receive a ${event.amount}% increase in cross-major teammate selection`;
    }
  }
  return eventString;
}

export function getDateDisplay(event: Event | UpgradeEvent | GameEvent) {
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - event.datetime.getTime();
  const numberOfDaysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  let dateDisplay = "";
  if (numberOfDaysAgo === 0) {
    dateDisplay = "today";
  } else if (numberOfDaysAgo === 1) {
    dateDisplay = "yesterday";
  } else {
    dateDisplay = `${numberOfDaysAgo} days ago`;
  }
  return dateDisplay;
}
