export interface User {
  name: string;
  discordId: string;
  major: string;
  totalCP: string;
  totalXP?: string;
  rank: number;
}

export interface Major {
  name: string;
  numMembers: number;
  maxMembers: number;
  totalXP: number;
  level: number;
  xpToNextLevel: number;
  rank: number;
  numFirstPlaceWins: number;
  votingPower: number;
}

export interface GameEvent {
  userName: string;
  xp: number;
  cp: number;
  placement: number;
  gameEvent: string;
  major: string;
  datetime: Date;
}

export interface UpgradeEvent {
  level: number;
  upgrade: string;
  powerType: string;
  amount: number;
  decidedBy: string;
  datetime: Date;
  major: string;
}

export interface Event {
  datetime: Date;
  eventType: EventTypes;
  event: GameEvent | UpgradeEvent;
}

export enum EventTypes {
  GAME = "Game",
  UPGRADE = "Upgrade",
}

export enum MajorTypes {
  EXPRESSION = "Expression",
  PERCEPTION = "Perception",
  AMBITION = "Ambition",
  CONNECTION = "Connection",
}

export enum Navigation {
  MAJORS = "/",
  LEADERBOARD = "/leaderboard",
  COMMUNITY = "/community",
  GAMES = "/games",
  SHOP = "/shop",
}
